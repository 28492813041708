import { Color } from "./Color";
import { MosquitoNetProfileType } from "./MosquitoNetProfileType";

export class MosquitoNetType {
  constructor(readonly id: string, readonly name: string, readonly minimalCost: number, readonly colors: Color[]) {

  }

  get allColors(): Color[] {
    return this.colors
  }

  get defaultColor(): Color {
    return this.colors[0]
  }

  findColorById(id: string): Color {
    const color = this.allColors.find(e => e.id == id)
    if (!color)
      throw new Error("No color found for id = " + id)
    return color;
  }

}

const values = {
  [MosquitoNetProfileType.standart.id]: [
    new MosquitoNetType(crypto.randomUUID(), "Сетка Стандарт", 10, [
      new Color(crypto.randomUUID(), "Белый", 13),
      new Color(crypto.randomUUID(), "Коричневый", 14),
      new Color(crypto.randomUUID(), "Золотой дуб", 14),
      new Color(crypto.randomUUID(), "Орех", 14),
      new Color(crypto.randomUUID(), "Махагон", 14),
      new Color(crypto.randomUUID(), "Антрацит", 14),
      new Color(crypto.randomUUID(), "Темно-коричневый", 14),
      new Color(crypto.randomUUID(), "Серебро", 20),
    ]),
    new MosquitoNetType(crypto.randomUUID(), "Сетка Невидимка", 14, [
      new Color(crypto.randomUUID(), "Белый", 17),
      new Color(crypto.randomUUID(), "Коричневый", 18),
      new Color(crypto.randomUUID(), "Золотой дуб", 18),
      new Color(crypto.randomUUID(), "Орех", 18),
      new Color(crypto.randomUUID(), "Махагон", 18),
      new Color(crypto.randomUUID(), "Антрацит", 18),
      new Color(crypto.randomUUID(), "Темно-коричневый", 18),
      new Color(crypto.randomUUID(), "Серебро", 24),
    ]),
    new MosquitoNetType(crypto.randomUUID(), "Сетка Алюминий", 17, [
      new Color(crypto.randomUUID(), "Белый", 21),
      new Color(crypto.randomUUID(), "Коричневый", 22),
      new Color(crypto.randomUUID(), "Золотой дуб", 22),
      new Color(crypto.randomUUID(), "Орех", 22),
      new Color(crypto.randomUUID(), "Махагон", 22),
      new Color(crypto.randomUUID(), "Антрацит", 22),
      new Color(crypto.randomUUID(), "Темно-коричневый", 22),
      new Color(crypto.randomUUID(), "Серебро", 28),
    ]),
    new MosquitoNetType(crypto.randomUUID(), "Сетка Алерген*", 20, [
      new Color(crypto.randomUUID(), "Белый", 25),
      new Color(crypto.randomUUID(), "Коричневый", 26),
      new Color(crypto.randomUUID(), "Золотой дуб", 26),
      new Color(crypto.randomUUID(), "Орех", 26),
      new Color(crypto.randomUUID(), "Махагон", 26),
      new Color(crypto.randomUUID(), "Антрацит", 26),
      new Color(crypto.randomUUID(), "Темно-коричневый", 26),
      new Color(crypto.randomUUID(), "Серебро", 34),
    ]),
    new MosquitoNetType(crypto.randomUUID(), "Pet Screen*", 20, [
      new Color(crypto.randomUUID(), "Белый", 25),
      new Color(crypto.randomUUID(), "Коричневый", 26),
      new Color(crypto.randomUUID(), "Золотой дуб", 26),
      new Color(crypto.randomUUID(), "Орех", 26),
      new Color(crypto.randomUUID(), "Махагон", 26),
      new Color(crypto.randomUUID(), "Антрацит", 26),
      new Color(crypto.randomUUID(), "Темно-коричневый", 26),
      new Color(crypto.randomUUID(), "Серебро", 34),
    ]),
  ],
  [MosquitoNetProfileType.exclusive.id]: [
    new MosquitoNetType(crypto.randomUUID(), "Сетка Стандарт", 12, [
      new Color(crypto.randomUUID(), "Белый", 16),
      new Color(crypto.randomUUID(), "Коричневый", 18),
      new Color(crypto.randomUUID(), "Золотой дуб", 18),
      new Color(crypto.randomUUID(), "Орех", 18),
      new Color(crypto.randomUUID(), "Махагон", 18),
      new Color(crypto.randomUUID(), "Антрацит", 18),
      new Color(crypto.randomUUID(), "Темно-коричневый", 18),
      new Color(crypto.randomUUID(), "Серебро", 18),
    ]),
    new MosquitoNetType(crypto.randomUUID(), "Сетка Невидимка", 16, [
      new Color(crypto.randomUUID(), "Белый", 20),
      new Color(crypto.randomUUID(), "Коричневый", 22),
      new Color(crypto.randomUUID(), "Золотой дуб", 22),
      new Color(crypto.randomUUID(), "Орех", 22),
      new Color(crypto.randomUUID(), "Махагон", 22),
      new Color(crypto.randomUUID(), "Антрацит", 22),
      new Color(crypto.randomUUID(), "Темно-коричневый", 22),
      new Color(crypto.randomUUID(), "Серебро", 22),
    ]),
    new MosquitoNetType(crypto.randomUUID(), "Сетка Алюминий", 20, [
      new Color(crypto.randomUUID(), "Белый", 24),
      new Color(crypto.randomUUID(), "Коричневый", 26),
      new Color(crypto.randomUUID(), "Золотой дуб", 26),
      new Color(crypto.randomUUID(), "Орех", 26),
      new Color(crypto.randomUUID(), "Махагон", 26),
      new Color(crypto.randomUUID(), "Антрацит", 26),
      new Color(crypto.randomUUID(), "Темно-коричневый", 26),
      new Color(crypto.randomUUID(), "Серебро", 26),
    ]),
    new MosquitoNetType(crypto.randomUUID(), "Сетка Алерген*", 23, [
      new Color(crypto.randomUUID(), "Белый", 28),
      new Color(crypto.randomUUID(), "Коричневый", 30),
      new Color(crypto.randomUUID(), "Золотой дуб", 30),
      new Color(crypto.randomUUID(), "Орех", 30),
      new Color(crypto.randomUUID(), "Махагон", 30),
      new Color(crypto.randomUUID(), "Антрацит", 30),
      new Color(crypto.randomUUID(), "Темно-коричневый", 30),
      new Color(crypto.randomUUID(), "Серебро", 30),
    ]),
    new MosquitoNetType(crypto.randomUUID(), "Pet Screen*", 23, [
      new Color(crypto.randomUUID(), "Белый", 28),
      new Color(crypto.randomUUID(), "Коричневый", 30),
      new Color(crypto.randomUUID(), "Золотой дуб", 30),
      new Color(crypto.randomUUID(), "Орех", 30),
      new Color(crypto.randomUUID(), "Махагон", 30),
      new Color(crypto.randomUUID(), "Антрацит", 30),
      new Color(crypto.randomUUID(), "Темно-коричневый", 30),
      new Color(crypto.randomUUID(), "Серебро", 30),
    ]),
  ],
  [MosquitoNetProfileType.door.id]: [
    new MosquitoNetType(crypto.randomUUID(), "Сетка Стандарт", 0, [
      new Color(crypto.randomUUID(), "Белый", 90),
      new Color(crypto.randomUUID(), "Коричневый", 90),
      new Color(crypto.randomUUID(), "Темно-коричневый", 100),
      new Color(crypto.randomUUID(), "Антрацит", 100),
      new Color(crypto.randomUUID(), "Золотой дуб (ламинация) ", 110),
      new Color(crypto.randomUUID(), "Орех (ламинация) ", 110),
      new Color(crypto.randomUUID(), "Махагон (ламинация) ", 110),
    ]),
    new MosquitoNetType(crypto.randomUUID(), "Сетка Невидимка или Зебра", 0, [
      new Color(crypto.randomUUID(), "Белый", 95),
      new Color(crypto.randomUUID(), "Коричневый", 95),
      new Color(crypto.randomUUID(), "Темно-коричневый", 105),
      new Color(crypto.randomUUID(), "Антрацит", 105),
      new Color(crypto.randomUUID(), "Золотой дуб (ламинация) ", 115),
      new Color(crypto.randomUUID(), "Орех (ламинация) ", 115),
      new Color(crypto.randomUUID(), "Махагон (ламинация) ", 115),
    ]),
    new MosquitoNetType(crypto.randomUUID(), "Сетка Алюминий", 0, [
      new Color(crypto.randomUUID(), "Белый", 103),
      new Color(crypto.randomUUID(), "Коричневый", 103),
      new Color(crypto.randomUUID(), "Темно-коричневый", 113),
      new Color(crypto.randomUUID(), "Антрацит", 113),
      new Color(crypto.randomUUID(), "Золотой дуб (ламинация) ", 123),
      new Color(crypto.randomUUID(), "Орех (ламинация) ", 123),
      new Color(crypto.randomUUID(), "Махагон (ламинация) ", 123),
    ]),
    new MosquitoNetType(crypto.randomUUID(), "Сетка Алерген*", 0, [
      new Color(crypto.randomUUID(), "Белый", 110),
      new Color(crypto.randomUUID(), "Коричневый", 110),
      new Color(crypto.randomUUID(), "Темно-коричневый", 120),
      new Color(crypto.randomUUID(), "Антрацит", 120),
      new Color(crypto.randomUUID(), "Золотой дуб (ламинация) ", 130),
      new Color(crypto.randomUUID(), "Орех (ламинация) ", 130),
      new Color(crypto.randomUUID(), "Махагон (ламинация) ", 130),
    ]),
    new MosquitoNetType(crypto.randomUUID(), "Pet Screen*", 0, [
      new Color(crypto.randomUUID(), "Белый", 110),
      new Color(crypto.randomUUID(), "Коричневый", 110),
      new Color(crypto.randomUUID(), "Темно-коричневый", 120),
      new Color(crypto.randomUUID(), "Антрацит", 120),
      new Color(crypto.randomUUID(), "Золотой дуб (ламинация) ", 130),
      new Color(crypto.randomUUID(), "Орех (ламинация) ", 130),
      new Color(crypto.randomUUID(), "Махагон (ламинация) ", 130),
    ]),
  ],
};


export const MosquitoNetTypes = {
  all: (profileType: MosquitoNetProfileType): MosquitoNetType[] => {
    return values[profileType.id]
  },

  default: (profileType: MosquitoNetProfileType): MosquitoNetType => {
    return values[profileType.id][0]
  },

  findById: (profileType: MosquitoNetProfileType, id: string): MosquitoNetType => {
    const mosquitoNetType = values[profileType.id].find(e => e.id == id)
    if (!mosquitoNetType)
      throw new Error("No mosquitoNetType found for id = " + id)
    return mosquitoNetType
  }
};

