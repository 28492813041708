import { ED_COST_MULTIPLIER } from '../constants';
import { Entry, IEntry } from '../Entry';
import { Color } from './Color';
import { MosquitoNetProfileType } from './MosquitoNetProfileType';
import { MosquitoNetType, MosquitoNetTypes } from './MosquitoNetType';

export interface IEntryMosquitoNet extends IEntry {
  color: Color;
  netType: MosquitoNetType;
  profileType: MosquitoNetProfileType;
  height: number;
  width: number;
  amount: number;
}

export class EntryMosquitoNet extends Entry {
  constructor(readonly uuid: string,
    readonly idx: number,
    readonly amount: number,
    readonly width: number,
    readonly height: number,
    readonly profileType: MosquitoNetProfileType,
    readonly netType: MosquitoNetType,
    readonly color: Color,
  ) {
    super(uuid, idx);
  }

  get totalCost(): number {
    return this.cost * this.amount
  }

  get totalFinalCost(): number {
    return this.totalCost * ED_COST_MULTIPLIER
  }

  get cost(): number {
    return this.profileType.cost(this.width, this.height, this.color.cost);
  }

  static create(idx: number): Entry {
    return new EntryMosquitoNet(
      crypto.randomUUID(),
      idx,
      1,
      500,
      500,
      MosquitoNetProfileType.standart,
      MosquitoNetTypes.default(MosquitoNetProfileType.standart),
      MosquitoNetTypes.default(MosquitoNetProfileType.standart).defaultColor,
    )
  }

  static fromData(data: IEntryMosquitoNet): Entry {
    return new EntryMosquitoNet(
      data.uuid,
      data.idx,
      data.amount,
      data.width,
      data.height,
      data.profileType,
      data.netType,
      data.color,
    );
  }
}
