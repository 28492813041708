import { IEntry } from "../../Models/Entry";
import { EntryMosquitoNet, IEntryMosquitoNet } from "../../Models/MosquitoNet";
import { Color } from "../../Models/MosquitoNet/Color";
import { MosquitoNetProfileType, MosquitoNetProfileTypes } from "../../Models/MosquitoNet/MosquitoNetProfileType";
import { MosquitoNetTypes } from "../../Models/MosquitoNet/MosquitoNetType";
import { EntryComponentProps, EntryRendererProps } from "../EntryComponent";

interface IEntryMosquitoNetComponentProps extends EntryComponentProps {
  data: IEntryMosquitoNet
}

const EntryRenderer: React.FC<IEntryMosquitoNetComponentProps> = ({ data, onDelete, onUpdate }) => {
  return (
    <article>
      <div className='grid'>
        <label>
          Рамка/Рулон/Дверь
          <select id="control-profile-type"
            value={data.profileType.id}
            onChange={e => {
              const profileType = MosquitoNetProfileType.ofValue(e.target.value)
              const netType = MosquitoNetTypes.default(profileType)
              const color = netType.defaultColor

              return onUpdate({ ...data, profileType: profileType, netType: netType, color: color } as IEntry);
            }}>
            <option value="">Select...</option>
            {MosquitoNetProfileTypes.all().map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Сетка
          <select id="control-net-type"
            value={data.netType.id}
            onChange={e => {
              const netType = MosquitoNetTypes.findById(data.profileType, e.target.value)
              return onUpdate({ ...data, netType: netType, color: netType.defaultColor } as IEntry);
            }}>
            <option value="">Select...</option>
            {MosquitoNetTypes.all(data.profileType).map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </label>
        <label>
          Цвет
          <select id="control-color"
            value={data.color.id}
            onChange={e => onUpdate({ ...data, color: data.netType.findColorById(e.target.value) } as IEntry)}>
            <option value="">Select...</option>
            {data.netType.allColors.map((element) => (
              <option key={element.id} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="grid">
        <label>
          Ширина
          <input type="number" value={data.width} onChange={e => onUpdate({ ...data, width: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
        <label>
          Высота
          <input type="number" value={data.height} onChange={e => onUpdate({ ...data, height: Number.parseInt(e.target.value) } as IEntry)}></input>
        </label>
      </div>

      <hr />
      <div className='grid'>
        <p className='centered-checkbox'>Цена: <span>{data.cost?.toFixed(2)}</span></p>
        <p className='centered-checkbox'>Всего: <span>{data.totalCost?.toFixed(2)}</span></p>
        <p className='centered-checkbox'>Всего (Ed?): <span>{data.totalFinalCost?.toFixed(2)}</span></p>
        <button onClick={(_e) => onDelete(data)}>Удалить</button>
      </div>
    </article >
  );
}

export const MosquitoNetEntires: React.FC<EntryRendererProps> = ({ entries, onDelete, onUpdate }) => {

  return (
    <div>
      {entries.filter(e => e instanceof EntryMosquitoNet).sort((a, b) => a.idx - b.idx).map(e => <EntryRenderer
        key={e.uuid}
        data={e as IEntryMosquitoNet}
        onDelete={(e) => onDelete(e)}
        onUpdate={(e) => onUpdate(e)}></EntryRenderer>
      )}
    </div>
  );
}
