export class MosquitoNetProfileType {
  static readonly standart = new MosquitoNetProfileType(1, "Standart", (width: number, height: number, materialCost: number) => width * height * 0.000001 * materialCost)
  static readonly exclusive = new MosquitoNetProfileType(2, "Exclusive", (width: number, height: number, materialCost: number) => width * height * 0.000001 * materialCost)
  static readonly door = new MosquitoNetProfileType(3, "Door", (width: number, height: number, materialCost: number) => materialCost)  // TODO: Если периметр двери более 6.4 метра, тогда доплата 25 евро за изделие

  static readonly values = [
    MosquitoNetProfileType.standart, MosquitoNetProfileType.exclusive, MosquitoNetProfileType.door
  ]

  private constructor(
    readonly id: number,
    readonly name: string,
    readonly priceCaltulator: (width: number, height: number, materialCost: number) => number) {
  }

  cost(width: number, height: number, materialCost: number): number {
    return this.priceCaltulator(width, height, materialCost);
  }

  static ofValue(value: string): MosquitoNetProfileType {
    const id = Number.parseInt(value);
    return MosquitoNetProfileType.values.find((v) => v.id == id) || MosquitoNetProfileType.values[0]
  }
}

export const MosquitoNetProfileTypes = {
  all: (): MosquitoNetProfileType[] => {
    return MosquitoNetProfileType.values;
  },

  default: (): MosquitoNetProfileType => {
    return MosquitoNetProfileType.values[0]
  },

  findById: (id: number): MosquitoNetProfileType => {
    const mosquitoNetType = MosquitoNetProfileType.values.find(e => e.id == id)
    if (!mosquitoNetType)
      throw new Error("No mosquitoProfileType found for id = " + id)
    return mosquitoNetType
  }
}
